import React, { FC, useState } from 'react';

import { RouteComponentProps } from '@reach/router';
import { Button, Form, Alert, Spinner } from 'react-bootstrap';

const submitURL = 'https://script.google.com/macros/s/AKfycbyuU8G4H2dnBlsCfdcEuldx8eLv-uQh8xzYoQEVMHqyvKIPjjtV/exec';

interface ContactProps {
  pageName: string;
  requireSenderInfo?: boolean;
  autoFocus?: boolean;
}

const Contact: FC<RouteComponentProps & ContactProps> = ({ pageName, requireSenderInfo, autoFocus }) => {
  const [validated, setValidated] = useState(false);
  const [sending, setSending] = useState(false);
  const [sent, setSent] = useState(false);
  const [sendError, setSendError] = useState('');

  const handleSubmit = (event: any) => {
    setValidated(true);

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }

    event.preventDefault();

    setSending(true);
    fetch(submitURL, {
      method: 'POST',
      body: new FormData(form),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.result !== 'success') {
          throw new Error(`bad result status '${data.result}'`);
        }
        setSent(true);
        if (sendError !== '') {
          setSendError('');
        }
      })
      .catch((error: Error) => {
        setSendError(error.message);
      })
      .finally(() => {
        setSending(false);
      });
  };

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit} className="mb-5">
      <Form.Control type="hidden" name="page" value={pageName} />
      <Form.Group>
        <Form.Label>
          <strong>Name:</strong>
          {!requireSenderInfo && ' (optional)'}
        </Form.Label>
        <Form.Control type="Name" name="name" placeholder="Your name" required={requireSenderInfo} autoFocus={autoFocus} />
        <Form.Control.Feedback type="invalid">Please enter your name.</Form.Control.Feedback>
      </Form.Group>

      <Form.Group>
        <Form.Label>
          <strong>Email:</strong>
          {!requireSenderInfo && ' (optional)'}
        </Form.Label>
        <Form.Control type="email" name="email" placeholder="Email address" required={requireSenderInfo} />
        <Form.Control.Feedback type="invalid">Please enter a valid email address.</Form.Control.Feedback>
      </Form.Group>

      <Form.Group>
        <Form.Label>
          <strong>Message:</strong>
        </Form.Label>
        <Form.Control as="textarea" name="message" type="message" placeholder="Message" rows={10} required />
        <Form.Control.Feedback type="invalid">Please enter a message.</Form.Control.Feedback>
      </Form.Group>

      {sendError !== '' && (
        <Alert variant="danger">
          <strong>Error sending message:</strong> {sendError}
          <br />
          <br />
          If this problem persists, please consider <a href="mailto:info@promlabs.com">sending an email</a> instead.
        </Alert>
      )}

      <button variant="secondary" type="submit" className="mt-2" disabled={sent}>
        {(sending && (
          <>
            <Spinner animation="border" size="sm" />
            &nbsp;&nbsp;Sending...
          </>
        )) ||
          (sent && 'Message Sent!') ||
          'Send Message'}
      </button>
    </Form>
  );
};

export default Contact;
