import React, { FC, useMemo, useState } from 'react';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { Jumbotron, Container, Row, Col, Card, ListGroup, Button, Collapse, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaTimes, FaCheck } from 'react-icons/fa';

import personalImage from '../images/undraw_researching_22gp.svg';
import businessImage from '../images/undraw_co-working_825n.svg';
import enterpriseImage from '../images/undraw_data_xmfy.svg';

import { FiMail } from 'react-icons/fi';
import NotifyForm from '../components/NotifyForm';
import Contact from '../components/Contact';

interface InfoTooltipProps {
  text: string;
  tooltip: string;
}

export const InfoTooltip: FC<InfoTooltipProps> = ({ text, tooltip }) => {
  return (
    <OverlayTrigger
      popperConfig={{ strategy: 'fixed' }}
      placement="bottom"
      overlay={(props: any) => (
        <Tooltip id={`info-tooltip`} {...props}>
          {tooltip}
        </Tooltip>
      )}
    >
      <span style={{ borderBottom: '2px dashed rgba(52, 79, 113, 0.2)' }}>{text}</span>
    </OverlayTrigger>
  );
};

const grafanaItem = (
  <InfoTooltip
    text="Grafana Datasource Selector"
    tooltip="Connect PromLens to your existing Grafana installation to show a dropdown in PromLens that allows you to select the Prometheus servers configured in Grafana without having to enter server URLs manually in PromLens."
  />
);

const shareLinksItem = (
  <InfoTooltip
    text="Link Sharing"
    tooltip="Share entire PromLens pages as links with your colleagues, preserving all relevant aspects of the page state, such as selected query nodes, visualization tabs, etc."
  />
);

const queryLinksItem = (
  <InfoTooltip
    text="Direct Query Links"
    tooltip="Share direct links to individual queries via a URL parameter without creating a shared page"
  />
);

const SignUpForm: FC<{ pricingType: string }> = ({ pricingType }) => {
  const [showSignUpForm, setShowSignUpForm] = useState<boolean>(false);

  return (
    <>
      {!showSignUpForm && (
        <button
          className="mt-4"
          onClick={() => {
            setShowSignUpForm(true);
            (window as any).plausible(pricingType === 'business' ? 'StartBusinessSignup' : 'StartEnterpriseSignup');
          }}
        >
          GET IN TOUCH
        </button>
      )}
      <Collapse in={showSignUpForm}>
        <div>
          <hr />
          <p style={{ fontSize: '0.9em', color: '#555' }}>
            <i>Leave your contact information below and we will be in touch with you about licensing details shortly.</i>
          </p>
          <NotifyForm pricingType={pricingType} />
        </div>
      </Collapse>
    </>
  );
};

const GetTrialForm: FC = () => {
  const [showGetTrialForm, setShowGetTrialForm] = useState<boolean>(false);

  return (
    <div className="get-trial-form">
      {!showGetTrialForm && (
        <button
          className="my-5"
          style={{ margin: 'auto', width: 400 }}
          onClick={() => {
            setShowGetTrialForm(true);
            (window as any).plausible('StartGetTrialLicense');
          }}
        >
          Get a <strong>30-day trial</strong> license key now
        </button>
      )}
      <Collapse in={showGetTrialForm}>
        <div style={{ width: 400, margin: 'auto' }}>
          <p style={{ fontSize: '0.9em', color: '#555' }}>
            <i>Enter your email below to receive a 30-day trial license key:</i>
          </p>
          <div>
            <NotifyForm pricingType="trial" />
          </div>
        </div>
      </Collapse>
    </div>
  );
};

const PricingPage = () => (
  <Layout>
    <SEO title="Pricing" />
    <div className="hero pb-4">
      <div className="hero-background"></div>
      <div className="hero-text">
        <h1>Stop flying blind. Get PromLens.</h1>
        <p className="hero-subtitle">
          <strong>Build, understand, and fix</strong> your queries much more effectively with the ultimate{' '}
          <strong>query builder for PromQL</strong>
        </p>
        <p className="py-5">
          PromLens works with any existing PromQL-compatible endpoint (e.g. <a href="https://prometheus.io/">Prometheus</a>,{' '}
          <a href="https://thanos.io/">Thanos</a>, <a href="https://cortexmetrics.io/">Cortex</a>,{' '}
          <a href="https://github.com/timescale/promscale">Promscale</a>, and more) and integrates with{' '}
          <a href="https://grafana.com/grafana/">Grafana</a> for easy selection of Prometheus datasources.
        </p>
      </div>
    </div>

    <Container fluid="xl">
      <GetTrialForm />

      <Row className="mb-5">
        <Col md="6">
          <Card className="pricing-card mb-5 mb-md-0">
            <Card.Img variant="top" src={personalImage} height={300} className="my-4" />
            <Card.Body>
              <Card.Title className="text-muted text-uppercase text-center">PERSONAL</Card.Title>
              <Card.Text className="text-center pricing-price">
                $0<span className="pricing-period"> / month</span>
              </Card.Text>
              <hr />
              <ListGroup className="pricing-list" variant="flush">
                <ListGroup.Item>
                  <strong>Personal and evaluation use only</strong>
                </ListGroup.Item>
                <ListGroup.Item className="text-muted">
                  <FaTimes /> Grafana Datasource Selector
                </ListGroup.Item>
                <ListGroup.Item className="text-muted">
                  <FaTimes /> Link Sharing
                </ListGroup.Item>
                <ListGroup.Item className="text-muted">
                  <FaTimes /> Direct Query Links
                </ListGroup.Item>
              </ListGroup>
              <a
                className="promlens-button mt-4"
                href="https://github.com/promlabs/promlens-public"
                onClick={() => (window as any).plausible('ClickDeployNow')}
                target="_blank"
                rel="noreferrer"
              >
                DEPLOY NOW
              </a>
            </Card.Body>
          </Card>
        </Col>
        {/* <Col lg="4">
          <Card className="pricing-card mb-5 mb-lg-0" style={{ borderWidth: 1 }}>
            <Card.Header className="text-center" style={{ borderBottom: 'none', fontWeight: 'bold' }}>
              Recommended
            </Card.Header>
            <Card.Img variant="top" src={businessImage} height={300} />
            <Card.Body>
              <Card.Title className="text-muted text-uppercase text-center">Business</Card.Title>
              <Card.Text className="text-center pricing-price">
                $20
                <span className="pricing-period"> / 1M series / month (billed anually)</span>
              </Card.Text>
              <hr />
              <ListGroup className="pricing-list" variant="flush">
                <ListGroup.Item>
                  <strong>Pay per 1M</strong> Series (self-certified)
                </ListGroup.Item>
                <ListGroup.Item>
                  <FaCheck /> {grafanaItem}
                </ListGroup.Item>
                <ListGroup.Item>
                  <FaCheck /> {shareLinksItem}
                </ListGroup.Item>
                <ListGroup.Item>
                  <FaCheck /> {queryLinksItem}
                </ListGroup.Item>
              </ListGroup>
              <SignUpForm pricingType="business" />
            </Card.Body>
          </Card>
        </Col> */}
        <Col md="6">
          <Card className="pricing-card mb-5 mb-md-0">
            <Card.Img variant="top" src={enterpriseImage} height={300} className="my-4" />
            <Card.Body>
              <Card.Title className="text-muted text-uppercase text-center">Business</Card.Title>
              <Card.Text className="text-center pricing-price">
                <span className="pricing-period">
                  <OverlayTrigger
                    popperConfig={{ strategy: 'fixed' }}
                    placement="bottom"
                    overlay={(props: any) => (
                      <Tooltip id={`info-tooltip`} {...props}>
                        We normally license PromLens on an annual subscription basis to an organization as a whole, without
                        any further restrictions in terms of number of users, deployments, or dataset size. We are happy to
                        discuss alternative models though.
                        <br />
                        <br />
                        Since there is no natural and automatically trackable metric by which to scale pricing, we mostly
                        base price quotes on a quick conversation about your company and use case.
                      </Tooltip>
                    )}
                  >
                    <span style={{ borderBottom: '2px dashed rgba(52, 79, 113, 0.2)' }}>
                      Custom pricing for your business
                    </span>
                  </OverlayTrigger>{' '}
                  - Get a quote!
                </span>
              </Card.Text>
              <hr />
              <ListGroup className="pricing-list" variant="flush">
                <ListGroup.Item>
                  <strong>Unlimited and commercial use</strong>
                </ListGroup.Item>
                <ListGroup.Item>
                  <FaCheck /> {grafanaItem}
                </ListGroup.Item>
                <ListGroup.Item>
                  <FaCheck /> {shareLinksItem}
                </ListGroup.Item>
                <ListGroup.Item>
                  <FaCheck /> {queryLinksItem}
                </ListGroup.Item>
              </ListGroup>
              <SignUpForm pricingType="enterprise" />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md={2} />
        <Col md={8}>
          <p className="text-center my-5">
            These plans don't work for you? Do you want a perpetual license, a SaaS version with all the features, or
            something else?{' '}
            <strong>
              Contact us via the form below or write us at{' '}
              <a href="mailto:info@promlabs.com">
                <FiMail /> info@promlabs.com
              </a>
              :
            </strong>
          </p>
          <Contact pageName="promlens-pricing" requireSenderInfo />
        </Col>
      </Row>
      {/* <h1 className="mt-5 mb-3">FAQ</h1>
      <p>Below are some answers to frequently asked questions around PromLens licensing and usage.</p>
      <h3 className="mt-5 mb-3">How do I install PromLens?</h3>
      <p>
        PromLens is currently available for on-premise installations as a Docker image. You can find detailed setup and
        configuration instructions in the{' '}
        <a href="https://github.com/promlabs/promlens-public/blob/master/README.md">public PromLens GitHub repository</a>.
      </p>
      <h3 className="mt-5 mb-3">How does charging per number of time series work?</h3>
      <p>
        When you buy a PromLens Business license, you can choose how many time series you want to use it with. You will have
        to self-certify that the total number of active series across all Prometheus servers (or API-compatible alternative
        systems) that you will be using PromLens with over the course of the license validity period is at most the selected
        number of series for the license.
      </p>
      <p>
        "Active series" means how many unique series your Prometheus servers (or API-compatible systems) track at any given
        time. For native Prometheus servers, you can get this number by looking at the{' '}
        <code>prometheus_tsdb_head_series</code> metric for each server, which tracks how many metrics have been indexed in
        the latest head block (which typically spans 2-3 hours). Let us know if you have questions around determining this
        number for other systems.
      </p>
      <h3 className="mt-5 mb-3">Series counts for HA (high availability) replicas or aggregators like Thanos</h3>
      <p>
        If your systems store the same series data multiple times for high availability (HA) reasons or because you are
        making the same data available via multiple PromQL endpoints (such as native Prometheus servers with a Thanos
        aggregation layer on top), only one copy of each series counts for the purpose of the per-series licensing.
      </p> */}
    </Container>
  </Layout>
);

export default PricingPage;
