import React, { FC, useState } from 'react';

import { RouteComponentProps } from '@reach/router';
import { Form, Alert, Spinner } from 'react-bootstrap';

const submitURL = 'https://script.google.com/macros/s/AKfycbyHtd8ciGoqIbqDoPOzk73Mjc9nhs-Df5KXoHZB-7IRNq47rsuh/exec';

interface NotifyFormProps {
  pricingType: string;
}

const NotifyForm: FC<RouteComponentProps & NotifyFormProps> = ({ pricingType }) => {
  const [validated, setValidated] = useState(false);
  const [sending, setSending] = useState(false);
  const [sent, setSent] = useState(false);
  const [sendError, setSendError] = useState('');

  const handleSubmit = (event: any) => {
    setValidated(true);

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }

    event.preventDefault();

    setSending(true);
    fetch(submitURL, {
      method: 'POST',
      body: new FormData(form),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.result !== 'success') {
          throw new Error(`bad result status '${data.result}'`);
        }
        setSent(true);
        if (sendError !== '') {
          setSendError('');
        }
      })
      .catch((error: Error) => {
        setSendError(error.message);
      })
      .finally(() => {
        setSending(false);
      });
  };

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit} className="mt-4">
      {!sent && (
        <>
          <Form.Control type="hidden" name="pricing-type" value={pricingType} />
          <Form.Group>
            <Form.Label>
              <strong>Name:</strong> (optional)
            </Form.Label>
            <Form.Control type="text" name="name" placeholder="Your name" autoFocus />
          </Form.Group>

          <Form.Group>
            <Form.Label>
              <strong>Email:</strong>
            </Form.Label>
            <Form.Control type="email" name="email" placeholder="Email address" required />
            <Form.Control.Feedback type="invalid">Please enter a valid email address.</Form.Control.Feedback>
          </Form.Group>

          {sendError !== '' && (
            <Alert variant="danger">
              <strong>Error signing up:</strong> {sendError}
              <br />
              <br />
              If this problem persists, please consider <a href="mailto:info@promlabs.com">sending an email</a> instead.
            </Alert>
          )}
        </>
      )}

      <button type="submit" className="mt-2" disabled={sent}>
        {(sending && (
          <>
            <Spinner animation="border" size="sm" />
            &nbsp;&nbsp;Sending...
          </>
        )) ||
          (sent && "Thanks, we'll get back to you!") ||
          'Submit'}
      </button>
    </Form>
  );
};

export default NotifyForm;
